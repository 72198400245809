// 搜索页面
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top ref="top" :white="$store.state.model.black"></Top>

    <div class="history_body">
      <div class="filbox">
        <!-- 搜索目标 -->
        <div class="search_top">
          <div class="search_goal-total" v-show="keyword">
            <div class="search_goal">
              <a>{{ keyword }}</a>
              <div class="search_goal-icon"></div>
            </div>
          </div>
          <a class="search_result">
            共有
            <a>{{ video_total }}</a>
            个筛选结果
          </a>
        </div>
        <!-- 标签筛选 -->
        <div class="filter_body">
          <div class="filb_left">
            <div class="filter_body_list" v-for="(item, key) in ccc1" :key="key">
              <div
                :class="key + '_fil'"
                class="fil_box"
                v-for="(value, index) in item"
                :key="index"
                @click="changerList(key, index)"
              >
                <a class="fil">
                  {{ value.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- 状态筛选 -->
        <div class="filter_sele">
          <div class="fil_sele_box">
            <div
              class="fil_sele-button fil_sele-button-act"
              @click="YS.actgo('fil_sele-button', 'fil_sele-button-act', 0)"
            >
              <a class="fil_sele-title">全部状态</a>
              <div class="fil_sele-icon"></div>
            </div>
            <div
              class="fil_sele-button"
              @click="YS.actgo('fil_sele-button', 'fil_sele-button-act', 1)"
            >
              <a class="fil_sele-title">更新时间</a>
              <div class="fil_sele-icon"></div>
            </div>
            <div
              class="fil_sele-button"
              @click="YS.actgo('fil_sele-button', 'fil_sele-button-act', 2)"
            >
              <a class="fil_sele-title">人气高低</a>
              <div class="fil_sele-icon"></div>
            </div>
            <div
              class="fil_sele-button"
              @click="YS.actgo('fil_sele-button', 'fil_sele-button-act', 3)"
            >
              <a class="fil_sele-title">评分高低</a>
              <div class="fil_sele-icon"></div>
            </div>
          </div>
        </div>
        <!-- 搜索结果 -->
        <div class="search_educt" v-for="(item, index) in video_list" :key="index">
          <div class="search_e-left" @click="clickSearchList(item.id)">
            <img src="../assets/text/8.jpg" />
          </div>
          <div class="search_e-right">
            <!-- 标题 -->
            <div class="search_e-title">
              <span>{{ item.title }}:{{ item.remark }}</span>
              <div><a>动作</a></div>
              <div><a>爱情</a></div>
              <div><a>谍战</a></div>
            </div>
            <!-- 简介 -->
            <div class="search_e-nodes">
              <span>{{ item.state }}：26</span>
              <span>导演：{{ item.director }}</span>
              <span>主演：{{ item.starring }}</span>
            </div>
            <!-- 集数 -->
            <div class="search_e-list-body">
              <div class="search_e-list" v-for="i in 8" :key="i">
                <div class="search_e-num"><a>1</a></div>
              </div>
              <div class="film_list">
                <div class="film_icon f_i-1"></div>
                <span>{{ item.comment }}</span>
              </div>
              <div class="film_list">
                <div class="film_icon f_i-2"></div>
                <span>{{ item.appreciate }}</span>
              </div>
              <div class="film_list">
                <div class="film_icon f_i-3"></div>
                <span>{{ item.depreciate }}</span>
              </div>
              <!-- 人气 -->
              <div class="poln_pop">
                <div></div>
                <a>{{ item.hits }}</a>
              </div>
              <!-- 评分 -->
              <div class="poln_score">
                <span class="score_num">{{ item.score.toFixed(1) }}</span>
                <span class="score_unit">分</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
import Top from '../components/Top'; //导航top
export default {
  components: {
    Top,
  },
  name: 'Search',
  data() {
    return {
      seaact: {},
      keyword: '',
      video_total: 0,
      video_list: [],
      ccc: [
        { name: '电影' },
        { name: '电视剧' },
        { name: '综艺' },
        { name: '动漫' },
        { name: '体育' },
        { name: '纪录片' },
        { name: '华人' },
        { name: '游戏' },
        { name: '新闻' },
      ],
      ccc1: {
        //全部类型
        type: [
          { name: '全部类型' },
          { name: '偶像' },
          { name: '爱情' },
          { name: '言情' },
          { name: '古装' },
          { name: '历史' },
          { name: '玄幻' },
          { name: '谍战' },
        ],
        //全部地区
        area: [
          { name: '全部地区' },
          { name: '大陆' },
          { name: '香港' },
          { name: '台湾' },
          { name: '日本' },
          { name: '韩国' },
          { name: '欧美' },
          { name: '英国' },
          { name: '泰国' },
          { name: '其他' },
        ],
        //全部语言
        language: [
          { name: '全部语言' },
          { name: '国语' },
          { name: '粤语' },
          { name: '英语' },
          { name: '韩语' },
          { name: '西班牙语' },
          { name: '法语' },
          { name: '意大利语' },
          { name: '其他' },
        ],
        //全部年份
        year: [
          { name: '全部年份' },
          { name: '今年' },
          { name: '去年' },
          { name: '更早' },
          { name: '90年代' },
          { name: '80年代' },
          { name: '怀旧' },
        ],
        //全部状态
        code: [{ name: '全部状态' }, { name: '全集' }, { name: '连载' }],
      },
      //记录当前筛选的下标值
      ccc2: {
        type: 0,
        area: 0,
        language: 0,
        year: 0,
        code: 0,
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    this.$route.query.id;
    // debugger;
  },
  mounted() {
    // if (Object.keys(that.$route.query).length) {
    //   Object.keys(that.$route.query).forEach((e) => {
    //     that.seaact[e] = that.$route.query[e];
    //   });
    // }
    that.YS.closeallWin(); //关闭所有弹窗
    that;
    that.changerList();
    that.getSearch(); //执行搜索
    // this.$refs.top.getSearch;
    let id = this.$route.query.id;
    id;
    // debugger;
  },
  methods: {
    changerList(type, i) {
      //如果没传参，默认全选
      if (!type) {
        Object.keys(that.ccc2).forEach((e) => {
          var obj = document.getElementsByClassName(e + '_fil');
          that.YS.actlist(obj, that.ccc2[e], 'fil-act');
        });
      } else {
        var obj1 = document.getElementsByClassName(type + '_fil');
        that.ccc2[type] = i;
        that.YS.actlist(obj1, that.ccc2[type], 'fil-act');
      }
    },
    // 获取搜索内容
    getSearch() {
      that.$store.state.searchNodes.keyword = this.$route.query.name;
      fetch.api_videolist(that.$store.state.searchNodes).then((data) => {
        // console.log(data);
        that.keyword = that.$store.state.searchNodes.keyword;
        that.$store.state.search_total = data.video_total;
        that.video_total = data.video_total;
        that.video_list = data.video_list;
        that.YS.closeallWin(); //关闭所有弹窗
      });
    },
    //点击搜索后里面的视频
    clickSearchList(id) {
      that.YS.routetogo({
        path: '/play',
        query: { video_id: id },
      });
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
//   .tag-input {
//     background: #eeeeee;
//     // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
//   }
//   .tag-span,
//   .right-span {
//     color: rgba(51, 51, 51, 1);
//   }
//   .bubble {
//     background: #91d6ff;
//   }
//   .map_icon {
//     background-image: url(../assets/img/down2.png);
//   }
//   .ri_3 {
//     background-image: url(../assets/img/recording4_black.png);
//   }
//   .ri_4 {
//     background-image: url(../assets/img/notice4_black.png);
//   }
// }
</style>
